<template>
    <v-card hover>
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">{{ popup_title }}</v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row no-gutters>
                <v-col class="text-h6 ml-4 mt-5 title">요일선택</v-col>
            </v-row>
            <v-row no-gutters>
                <v-chip-group multiple show-arrows rounded v-model="days" @change="weekDaysChanged" class="mt-3 ml-4">
                    <template v-for="(weekDaysItem, weekDaysIdx) in weekDaysSels">
                        <v-chip
                            v-bind:key="weekDaysIdx"
                            filter
                            :color="weekDaysItem.checked ? 'blue accent-3': ''"
                            :text-color="weekDaysItem.checked ? 'white' : 'grey'"
                            :disabled="!weekDaysItem.enabled"
                            :outlined="weekDaysItem.checked == false"
                            :value="weekDaysItem.val"
                        >{{ weekDaysItem.txt }}
                        </v-chip>
                    </template>
                </v-chip-group>
            </v-row>
            <v-row no-gutters class="mt-8">
                <v-col cols="6" class="justify-start" style="display: flex; align-items: center;">
                    <span style="font-size: 20px;" class="ml-4 title">시작</span>
                    <v-sheet outlined class="ml-4 elevation-1">
                        <v-row no-gutters style="display: flex; align-items: center;">
                            <v-col cols="2" class="d-flex justify-center" style="max-width: 85px;">
                                <v-btn class="text-h6 ma-0 pl-4" min-width="75" text :style="styleBtnTime" @click="changeTime('startHour')">{{ workTime.startHour }}</v-btn>
                                <template v-if="selectedTime.startHour">
                                    <v-list
                                        dense
                                        outlined
                                        class="text-center mt-9 overflow-y-auto" max-height="250" min-width="75"
                                        :style="styleVListComp">
                                        <v-list-item-group
                                            color="primary"
                                            class="mt-n2 mb-n3 justify-end"
                                            v-model="selectedTime.value"
                                        >
                                            <template v-for="(hour, idx) in hours">
                                                <v-list-item
                                                    :key="idx"
                                                    :value="hour"
                                                    :id="hour"
                                                    @click="setTime('startHour', hour)"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="hour"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </template>
                            </v-col>
                            <v-col class="ma-0 pa-0 font-weight-bold">:</v-col>
                            <v-col cols="2" class="d-flex justify-center" style="max-width: 85px;">
                                <v-btn class="text-h6 ma-0 pr-4" min-width="75" text :style="styleBtnTime" @click="changeTime('startMin')">{{ workTime.startMin }}</v-btn>
                                <template v-if="selectedTime.startMin">
                                    <v-list
                                        dense
                                        outlined
                                        class="text-center mt-9 overflow-y-auto" max-height="250" min-width="75"
                                        :style="styleVListComp">
                                        <v-list-item-group
                                            color="primary"
                                            class="mt-n2 mb-n3"
                                            v-model="selectedTime.value"
                                        >
                                            <template v-for="(min, idx) in minutes">
                                                <v-list-item
                                                    :key="idx"
                                                    :value="min"
                                                    :id="min"
                                                    @click="setTime('startMin', min)"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="min"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </template>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="6" class="justify-start" style="display: flex; align-items: center;">
                    <span style="font-size: 20px;" class="ml-4 title">종료</span>
                    <v-sheet outlined class="ml-4 elevation-1">
                        <v-row no-gutters style="display: flex; align-items: center;">
                            <v-col cols="2" class="d-flex justify-center" style="max-width: 85px;">
                                <v-btn class="text-h6 ma-0 pl-4 text-h6" min-width="75px" text :style="styleBtnTime" @click="changeTime('endHour')">{{ workTime.endHour }}</v-btn>
                                <template v-if="selectedTime.endHour">
                                    <v-list
                                        dense
                                        outlined
                                        class="text-center mt-9 overflow-y-auto" max-height="250" min-width="75"
                                        :style="styleVListComp">
                                        <v-list-item-group
                                            color="primary"
                                            class="mt-n2 mb-n3 justify-end"
                                            v-model="selectedTime.value"
                                        >
                                            <template v-for="(hour, idx) in hours">
                                                <v-list-item
                                                    :key="idx"
                                                    :value="hour"
                                                    :id="hour"
                                                    @click="setTime('endHour', hour)"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="hour"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </template>
                            </v-col>
                            <v-col class="ma-0 pa-0 font-weight-bold">:</v-col>
                            <v-col cols="2" class="d-flex justify-center" style="max-width: 85px;">
                                <v-btn class="text-h6 ma-0 pr-4" min-width="75" text :style="styleBtnTime" @click="changeTime('endMin')">{{ workTime.endMin }}</v-btn>
                                <template v-if="selectedTime.endMin">
                                    <v-list
                                        dense
                                        outlined
                                        class="text-center mt-9 overflow-y-auto" max-height="250" min-width="75"
                                        :style="styleVListComp">
                                        <v-list-item-group
                                            color="primary"
                                            class="mt-n2 mb-n3"
                                            v-model="selectedTime.value"
                                        >
                                            <template v-for="(min, idx) in minutes">
                                                <v-list-item
                                                    :key="idx"
                                                    :value="min"
                                                    :id="min"
                                                    @click="setTime('endMin', min)"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="min"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </template>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="mt-3 mb-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-6 pt-0 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    props: ['editMode', 'itemIdx'],
    components: {
        VueTimepicker,
    },
    data: () => ({
        conf: {},
        popup_title: '',
        week_days: [],
        start_time: '0000',
        end_time: '0000',
        days: [],
        weekDaysSels: [
            {txt: '일', val: '0', enabled: true, checked: false},
            {txt: '월', val: '1', enabled: true, checked: false},
            {txt: '화', val: '2', enabled: true, checked: false},
            {txt: '수', val: '3', enabled: true, checked: false},
            {txt: '목', val: '4', enabled: true, checked: false},
            {txt: '금', val: '5', enabled: true, checked: false},
            {txt: '토', val: '6', enabled: true, checked: false}
        ],
        workHours: [],
        workTime: {
            startHour: '',
            startMin: '',
            endHour: '',
            endMin: ''
        },
        selectedTime: {
            'type': '',
            'value': '',
            'startHour': false,
            'startMin': false,
            'endHour': false,
            'endMin': false
        },
        hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        minutes: ['00', '10', '20', '30', '40', '50'],
        styleVList: {
            width: '60px',
            position: 'fixed',
            zIndex: '200',
            boxShadow: '0 0 15px 2px rgba(0,0,0,0.07)',
            borderRadius: '5px',
            overscrollBehaviorY: 'contain',
            scrollSnapType: 'y mandatory'
        }
    }),
    computed: {
        ...mapGetters({}),
        startTime() {
            return this.workTime.startHour + this.workTime.startMin
        },
        endTime() {
            return this.workTime.endHour + this.workTime.endMin
        },
        styleBtnTime() {
            return {'color': '#000000'}
        },
        styleVListComp() {
            let ret = this.styleVList
            if (this.selectedTime.type && this.selectedTime[this.selectedTime.type]) {
                ret.scrollSnapType = 'y mandatory'
            }

            return ret
        }
    },
    mounted() {
        let workTimeConf = this.$store.getters['svcVoiceIntro/getWorkTimeConf']
        this.conf = workTimeConf

        if (this.editMode == 'add') {
            this.popup_title = '업무시간 추가'
            this.initForAdd(workTimeConf.workHours)
        } else if (this.editMode == 'modify') {
            this.popup_title = '업무시간 수정'
            this.initForModify(workTimeConf.workHours, this.itemIdx)
        }
    },
    methods: {
        initForAdd(workHours) {
            this.calcAvailabeDays(workHours)
            this.workTime.startHour = '09'
            this.workTime.startMin = '00'
            this.workTime.endHour = '18'
            this.workTime.endMin = '00'

            console.log(this.workTime)
        },
        initForModify(workHours, itemIdx) {
            this.calcAvailabeDays(workHours)

            if (itemIdx < 0 || workHours.length <= itemIdx) {
                return
            }

            let curItem = workHours[itemIdx]
            if (curItem) {
                let curDays = curItem.days.split('|')
                this.weekDaysSels.forEach(selItem => {
                    curDays.forEach(cd => {
                        if (cd == selItem.val) {
                            selItem.enabled = true
                            selItem.checked = true
                        }
                    })
                })
                this.workTime.startHour = curItem.work_start.substring(0, 2)
                this.workTime.startMin = curItem.work_start.substring(2, 4)
                this.workTime.endHour = curItem.work_end.substring(0, 2)
                this.workTime.endMin = curItem.work_end.substring(2, 4)
                this.days = curDays
            }

        },
        calcAvailabeDays(workHours) {
            if (!workHours) {
                workHours = []
            }

            let usedDays = []
            workHours.forEach(workHoursItem => {
                let days = workHoursItem.days.split('|')
                days.forEach(dayItem => {
                    usedDays.push(dayItem)
                })
            })

            this.weekDaysSels.forEach(selItem => {
                selItem.enabled = true

                usedDays.forEach(ud => {
                    if (ud == selItem.val) {
                        selItem.enabled = false
                    }
                })
            })

        },
        weekDaysChanged() {
            let va = this
            this.weekDaysSels.forEach(selItem => {
                let checked = false
                va.days.forEach(dayItem => {
                    if (selItem.val == dayItem) {
                        checked = true
                    }
                })
                selItem.checked = checked
            })
        },
        closePopup() {
            this.$emit('cancel')
        },
        checkedDay: function (idx) {
            for (let k in this.days) {
                if (this.days[k] == idx) {
                    return true
                }
            }
            return false
        },
        submitPopup() {
            if (this.days.length == 0) {
                this.$store.dispatch('openAlert', {
                    message: '요일을 선택해 주세요',
                    sub_message: '업무시간을 설정할 요일을 선택해 주세요'
                })
                return
            }

            let daysStr = ''
            this.days.forEach((dayItem, dayIdx) => {
                daysStr = daysStr + (dayIdx > 0 ? '|' : '') + dayItem
            })

            let workHours = {
                days: daysStr,
                work_start: this.startTime,
                work_end: this.endTime
            }

            if (this.editMode == 'add') {
                this.$store.commit('svcVoiceIntro/addWorkHours', workHours)
            } else if (this.editMode == 'modify') {
                this.$store.commit('svcVoiceIntro/modifyWorkHours', {idx: this.itemIdx, item: workHours})
            }

            this.$emit('submit')
        },
        changeTime(type) {
            if (this.selectedTime.type == type) {
                this.selectedTime[type] = false
                this.selectedTime.type = ''
                this.selectedTime.value = ''
            } else {
                this.selectedTime[this.selectedTime.type] = false
                this.selectedTime.type = type
                this.selectedTime[type] = true
                this.selectedTime.value = this.workTime[this.selectedTime.type]
            }

            this.$nextTick(() => {
                if (document.getElementById(this.selectedTime.value)) {
                    document.getElementById(this.selectedTime.value).style.scrollSnapAlign = 'start'
                    setTimeout(() => {
                        this.styleVList.scrollSnapType = ''
                    }, 300)
                }
            })
        },
        setTime(type, value) {
            this.selectedTime[type] = false
            this.selectedTime.type = ''
            this.workTime[type] = value
        }
    }
}
</script>
